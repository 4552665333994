import React from "react"

const styles = {
  header: {
    margin: "0 auto",
    padding: "1.5rem 0",
    border: "none",
    backgroundColor: "rgba(246,247,248,1)",
  },
  h1: {
    fontSize: "1rem",
    fontWeight: "normal",
    textAlign: "left",
  },
}

const Header = () => (
  <header style={styles.header}>
    <div className="wrap">
      <h1 style={styles.h1}>
        <b>P&amp;M:</b> Föli-aikataulut
      </h1>
    </div>
  </header>
)

export default Header
