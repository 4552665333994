import React from "react"

const styles = {
  footer: {
    display: "block",
    margin: "0 auto",
    padding: "1.5rem",
    border: "none",
    textAlign: "center",
  },
  quote: {
    fontSize: ".75rem",
    lineHeight: "1.4",
    padding: "0 .5rem",
    color: "rgba(149,158,166,1)",
    textDecoration: "none",
    border: "none",
  },
  source: {
    margin: "3rem auto 0 auto",
    padding: "0 .5rem",
    fontSize: ".75rem",
    lineHeight: "1.4",
    color: "rgba(149,158,166,.5)",
  },
  sourceA: {
    color: "rgba(149,158,166,.5)",
    textDecoration: "none",
    border: "none",
  },
}

const Footer = () => (
  <footer style={styles.footer}>
    <div className="wrap">
      <a
        style={styles.quote}
        href="https://www.imdb.com/title/tt0098627/quotes?item=qt0430842"
      >
        “Only half hour? I'm usually forty-five minutes late. I'm early today.”
      </a>

      <p style={styles.source}>
        Turun seudun joukkoliikenteen liikennöinti- ja aikatauludata. Aineiston
        ylläpitäjä on Turun kaupungin joukkoliikennetoimisto. Aineisto on
        ladattu palvelusta{" "}
        <a style={styles.sourceA} href="https://data.foli.fi/">
          data.foli.fi
        </a>{" "}
        lisenssillä Creative Commons Nimeä 4.0 Kansainvälinen (CC BY 4.0).
      </p>
    </div>
  </footer>
)

export default Footer
