import React, { useState, useEffect } from "react"
import axios from "axios"
import formatTime from "./helpers/formatTime"
import useInterval from "./helpers/useInterval"

const styles = {
  section: {
    display: "block",
    margin: "2rem 0",
  },
  h1: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    display: "block",
    color: "rgba(34,34,34,1)",
    padding: "0 .25rem",
  },
  exp: {
    color: "rgba(34,34,34,1)",
    fontSize: "75%",
    fontWeight: "normal",
    display: "inline-block",
    margin: "0 0 0 -.25rem",
  },
  table: {
    width: "100%",
    margin: ".5rem auto 0 auto",
    border: "1px solid lightgray",
    textAlign: "left",
    lineHeight: "1",
    borderCollapse: "collapse",
  },
  th: {
    padding: ".5rem .25rem",
    border: "1px solid lightgray",
    nolines: {
      padding: "1.5rem 1rem",
      textAlign: "center",
      color: "rgba(34,34,34,.5)",
      fontWeight: "normal",
    },
  },
  td: {
    first: {
      width: "33%",
      padding: ".5rem .25rem",
      border: "1px solid lightgray",
    },
    padding: ".5rem .25rem",
    border: "1px solid lightgray",
  },
  late: {
    display: "inline-block",
    verticalAlign: "middle",
    padding: ".25rem",
    margin: "-3px 0 0 .5rem",
    fontSize: "50%",
    background: "#cc1228",
    color: "white",
  },
  early: {
    display: "inline-block",
    verticalAlign: "middle",
    padding: ".25rem",
    margin: "-3px 1rem 0 .5rem",
    fontSize: "50%",
    background: "rgba(144,238,144,1)",
    color: "rgba(0,0,0,.33)",
  },
  loader: {
    display: "inline-block",
    float: "right",
    color: "rgba(0,0,0,.33)",
  },
}

async function fetchData(theStop, theLines, setBusses, setLoading) {
  setLoading(true)

  await axios({
    method: "get",
    url: `https://data.foli.fi/siri/sm/${theStop}`, // Lisää osoitteeseen /pretty, jos haluat ihmisluettavassa muodossa.
    timeout: 1000,
  })
    .then(response => {
      // Suodatetaan vain ne linjat, joista ollaan kiinnostuneita
      setBusses(
        response.data.result.filter(bus => theLines.includes(bus.lineref))
      )

      setLoading(false)
      console.log(`${theStop} päivitetty!`)
    })
    .catch(error => {
      console.log(error)
    })
}

const Timetable = props => {
  const [busses, setBusses] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchData(props.theStop, props.theLines, setBusses, setLoading)
  }, [props.theStop, props.theLines])

  useInterval(() => {
    if (!loading) {
      fetchData(props.theStop, props.theLines, setBusses, setLoading)
    }
  }, 10000)

  return (
    <section style={styles.section}>
      <div className="wrap">
        <h1 style={styles.h1}>
          {props.theStop} <span style={styles.exp}>{props.explanation}</span>
          {loading && <span style={styles.loader}>…</span>}
        </h1>

        {busses.length > 0 ? (
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>Linja</th>
                <th style={styles.th}>Lähtöaika</th>
              </tr>
            </thead>
            <tbody>
              {busses.map((bus, i) => (
                <tr key={i}>
                  <td style={styles.td.first}>{bus.lineref}</td>
                  <td style={styles.td}>
                    {formatTime(bus.aimedarrivaltime)}
                    {bus.aimeddeparturetime < bus.expecteddeparturetime && (
                      <span style={styles.late}>
                        Myöhässä (+
                        {Math.floor(
                          (bus.expecteddeparturetime - bus.aimeddeparturetime) /
                            60
                        )}{" "}
                        min)
                      </span>
                    )}
                    {bus.aimeddeparturetime > bus.expecteddeparturetime && (
                      <span style={styles.early}>
                        Edellä (-
                        {Math.floor(
                          (bus.aimeddeparturetime - bus.expecteddeparturetime) /
                            60
                        )}{" "}
                        min)
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th.nolines}>Ei busseja</th>
              </tr>
            </thead>
          </table>
        )}
      </div>
    </section>
  )
}

export default Timetable
