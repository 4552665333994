import React from "react"
import Header from "./Header.js"
import Footer from "./Footer.js"
import Timetable from "./Timetable.js"

const App = () => (
  <div className="App">
    <Header />

    <Timetable
      explanation="Vesilaitokselta kauppatorille"
      theStop="213"
      theLines={["55", "55A", "56", "36"]}
    />
    <Timetable
      explanation="Kauppatorilta vesilaitokselle"
      theStop="T13"
      theLines={["55", "55A", "56", "36"]}
    />
    <Timetable
      explanation="Kauppatorilta Yo-kylään"
      theStop="T42"
      theLines={["50", "51", "53", "54", "36"]}
    />
    <Timetable
      explanation="Tuomiokirkolta vesilaitokselle"
      theStop="1684"
      theLines={["55", "55A", "56", "36"]}
    />

    <Footer />
  </div>
)

export default App
