const formatTime = unixTime => {
  let date = new Date(unixTime * 1000)
  let hours = date.getHours()
  let minutes = "0" + date.getMinutes()
  const humanTime = hours + ":" + minutes.substr(-2)

  return humanTime
}

export default formatTime
